.App {
  background-color: #282c34;
}

.backround {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  font-family: Verdana, sans-serif;
}