.Login {
  background-color: rgb(94, 38, 213);
  padding: "20px",
}

.loginbackround {
  background-color: rgb(94, 38, 213);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 50px;
}

.typewriter p {
  overflow: hidden;
  border-right: .15em solid orange;
  white-space: nowrap;

  letter-spacing: .15em;
  animation:
    typing 3.5s steps(40, end),
    blink-caret .75s step-end infinite;

}


@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}


@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: orange;
  }
}