.col-sm {

    background-color: rgb(182, 148, 255);
    border: 1px solid rgb(182, 148, 255);
    border-radius: 0.5rem;
}

.text {
    padding: "20px";
    font-family: 'Courier New', Courier, monospace;
    font: monospace;
    font-style: bold;
}

.App-logo {}

.link {
    text-decoration: none;
    color: white;
}

.link:hover {
    color: rgb(94, 38, 213);
}