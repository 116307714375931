main {
  padding: 3%;
  height: 69vh;
  top: 21vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-width: 5px;
  position: relative;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #ffffff;
}

main::-webkit-scrollbar-thumb {
  background: #ffffff;
}

form {
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: #5e26d5;
  width: 100%;
  padding: 1%;
  color: white;
  display: flex;
  font-size: 1.5rem;
  border-radius: 9px 9px 0px 0px;
}

nn {
  width: 15%;
  background-color: #4c465a;
  border-radius: 5px;
}

::placeholder {
  color: white;
}

input {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;

  background: #5e26d5;
  color: rgb(255, 255, 255);
  outline: none;
  border: none;
  border-radius: 4px 0px 0px 4px;
  padding: 0 10px;
}

.send-message {
  background-color: #4c465a;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
  border-radius: 4px;
}

.send-message:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

ul,
li {
  text-align: left;
  list-style: none;
}

.message {
  display: flex;
  align-items: center;
  /*Add padding to button*/
  padding: 10px;
  /*Add margin to bottom of button*/
  margin-bottom: 10px;
  /*Add border*/
  border: 1px solid #ccc;
  /*Add border radius*/
  border-radius: 5px;
  /*Add box shadow*/
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  /*Add transition*/
  transition: all 0.2s ease-in-out;
}

.sent {
  flex-direction: row-reverse;
}

.sent {
  color: white;
  background: #5e26d5;
  align-self: flex-end;
  border: 1px solid#cbcace;
  border-radius: 0.25rem;
  padding: 1%;
}

.received {
  background: #4c465a;
  color: rgb(255, 255, 255);
  align-self: flex-start;
  border: 1px solid#0e0c0b;
  border-radius: 0.25rem;
  padding: 1%;
}
