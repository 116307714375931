* {
  box-sizing: border-box;

}

body {
  margin: 50;

}

.container {

  margin: 1rem;
  text-align: left;

}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1.5rem;
  border-bottom: solid;
}

.site-title {
  font-size: 2rem;


  font-family: "Poppins";
  font: monospace;
  font-weight: bold;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  color: rgb(94, 38, 213);
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .125rem;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font: monospace;
  font-size: 1.25rem;

}

.nav li.active {
  background-color: rgb(241, 234, 255);
}

.nav li:hover {
  background-color: rgb(241, 234, 255);
}