.wrapper {
  width: 100%;
  height: 100%;
  margin-top: 180px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wrapper img {
  max-width: 25%;
  max-height: 25%;
}

.wrapper button {
  margin-top: 50px;
  width: 300px;
  height: 60px;
  border-radius: 15px;
  color: rgb(94, 38, 213);
  border: 5px solid rgb(94, 38, 213);
  background: transparent;
  font-size: 30px;
  font-weight: 700;
  transition: all ease 0.5s;
  cursor: pointer;
}

.wrapper button:hover {
  background: rgb(94, 38, 213);
  color: white;
}
